// COMPONENTS
//////////////////////////////////////////////////////////////////////////

body {
  // POPCONFIRM
  .ant-popconfirm-buttons {
    display: flex;
  }

  //LAYOUT
  .app-layout {
    background-size: cover;
    background-repeat: no-repeat;
  }

  //MAIN
  .app-content.ant-layout-content {
    height: calc(100vh - $header-lg);
    overflow: auto;
    // padding-right: 8px;
    // border-radius: 4px;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #0c0a0b;
    }

    &::-webkit-scrollbar-thumb {
      background: linear-gradient(14.93deg, #ff2f6180 -42.27%, #ffb801b3 95.89%);
      height: 500px;
      border-radius: 4px;
    }
  }

  //SIDER
  .ant-layout {
    &-sider {
      &::after {
        content: "";

        position: absolute;
        top: 0;
        right: 0;

        display: block;
        height: 100%;
        width: 3px;
      }
    }
  }

  //MENU
  .ant-menu {
    background-color: transparent;

    &.ant-menu-inline,
    &.ant-menu-vertical {
      border-inline-end: none !important;
    }

    .ant-menu-sub.ant-menu-inline {
      background-color: transparent !important;
      padding-left: 24px;
    }

    .ant-menu-item {
      display: flex;
      align-items: center;
    }
  }

  // BUTTON
  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    &.ant-btn-sm {
      padding: 8px 16px;
      font-size: 12px;
    }

    &.ant-btn-lg {
      @include breakpoint("size-xl-down") {
        font-size: 15px;
        height: 40px;
        padding: 7.2px 15px;
        border-radius: 14px;
      }
    }

    &.ant-btn-dashed {
      border-width: 0;
      box-shadow: none;
    }

    &.ant-btn-dashed:hover {
      color: $primary-color !important;
    }

    &.ant-btn-default {
      box-shadow: none;
    }

    .btn-icon {
      margin-right: 8px;
    }

    span,
    .ant-typography {
      font-weight: 500;
    }

    &.ant-btn-primary {
      background-color: $primary-color;
      box-shadow: 0px 6.75px 34.5px rgba(245, 163, 44, 0.12);
    }
  }

  //TABS
  .ant-tabs {
    .ant-tabs-nav {
      &::before {
        border-width: 2px;
      }
    }
    .ant-tabs-tab {
      font-weight: 500;

      &-active {
        font-weight: 600;
      }
    }

    .ant-tabs-ink-bar {
      background-color: transparent;

      &::before {
        content: "";

        position: absolute;
        top: 50%;
        left: 50%;

        display: block;
        width: 16px;
        height: 2px;
        border-radius: 1.5px;

        background-color: $primary-color;
        transform: translate(-50%, -50%);

        box-shadow: 0 7px 35px 0 $primary-color;
      }
    }
  }

  // FORM
  .ant-form {
    &.ant-form-vertical {
      .ant-form-item {
        .ant-form-item-label {
          padding: 0;
        }
      }
    }
  }

  //INPUT
  /* Change autocomplete styles in WebKit */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: $primary-color;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  }

  input:-webkit-autofill {
    -webkit-background-clip: text;
  }

  .ant-input-affix-wrapper {
    .ant-input-prefix {
      margin-right: 8px;
    }

    &.search-input-wrapper {
      width: 300px;
    }
  }

  //RADIO
  .ant-radio-group {
    .ant-radio-button-wrapper-checked {
      box-shadow: 0px 4.5px 23px rgba(245, 163, 44, 0.12);

      &::before {
        content: none;
      }
    }

    .ant-radio-button-wrapper {
      svg {
        vertical-align: middle;
      }

      &.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):hover {
        color: $primary-color;
      }
    }
  }

  // MODAL
  .ant-modal {
    .ant-modal-title {
      margin-bottom: 24px;
    }

    .modal-footer {
      width: 100%;
      justify-content: flex-end;
    }

    .ant-modal-close {
      width: 24px;
      height: 24px;

      color: $primary-color;

      &:hover {
        color: $primary-color;
      }
    }
  }

  //TABLE
  .ant-table-wrapper {
    .ant-table {
      border-top-right-radius: 14px;
      border-top-left-radius: 14px;
    }

    .ant-table-thead tr:first-child {
      .ant-table-cell:first-child {
        border-top-left-radius: 14px;
      }

      .ant-table-cell:last-child {
        border-top-right-radius: 14px;
      }
    }
  }

  //DRAWER
  .ant-drawer {
    .ant-drawer-header {
      height: $header-lg !important;
      line-height: $header-lg !important;

      flex-basis: auto;

      @include breakpoint("size-sm-down") {
        height: $header-sm !important;
        line-height: $header-sm !important;
      }
    }

    .ant-drawer-close {
      width: 24px;
      height: 24px;
      padding: 0;
      border-radius: 4px;
      margin-right: 8px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.12);
      }

      &:active {
        background-color: rgba(255, 255, 255, 0.18);
      }
    }
  }

  .menu-drawer {
    .ant-drawer-body {
      padding: 0;
    }

    .ant-menu-item {
      height: 48px;
      line-height: 48px;

      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  //POPOVER
  .ant-popover {
    .ant-popover-inner {
      padding: 16px;
    }
  }

  //CAROUSEL
  .ant-carousel {
    .slick-slider {
      button.slick-arrow {
        border-radius: 14px;
        display: flex !important;
        align-items: center;
        justify-content: center;

        &:after {
          position: static;
        }
      }
    }
  }
}

body.dark {
  //LAYOUT
  .app-layout {
    background-image: url("~assets/icon/pattern-dark-mode.svg");
    background-color: $bg-dark-color;
  }
  .render-tab-bar {
    background-color: $bg-dark-color;
  }

  .ant-layout {
    &-sider {
      background: linear-gradient(175deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0) 100%);

      background-size: 100% 100%;
      backdrop-filter: blur(10px); /* apply a blur to the background */
      transform: translateZ(0); /* trigger hardware acceleration */

      &::after {
        background: linear-gradient(
          4.01deg,
          rgba(81, 35, 37, 0) 0%,
          rgba(81, 35, 37, 0) 39.73%,
          rgba(245, 163, 44, 0) 42.48%,
          rgba(245, 163, 44, 0.5) 100%
        );
      }
    }
  }

  //TYPOGRAPHY
  .ant-typography.card-subtitle {
    color: $grey-dark-color;
  }

  .ant-typography.caption {
    color: $emphasis-medium-dark-color;
  }

  //BUTTON
  .ant-btn {
    &.sider-trigger {
      color: $emphasis-small-dark-color;
    }

    &.ant-btn-dashed {
      background-color: rgba(255, 255, 255, 0.08);
      color: $emphasis-medium-dark-color;
    }
  }

  //RADIO
  .ant-radio-group {
    .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
      color: $emphasis-medium-dark-color;
    }
  }

  //TABS
  .ant-tabs {
    .ant-tabs-tab {
      color: $emphasis-medium-dark-color;
      &-active {
        color: $primary-color;
      }
    }
  }

  //DRAWER
  .ant-drawer {
    .close-icon {
      color: $emphasis-medium-dark-color;
    }

    .ant-drawer-header {
      border-bottom: 2px solid rgba(25, 22, 22, 0.7);
    }

    .ant-drawer-content {
      background-color: #211d1e;
    }
  }
}

body.light {
  //LAYOUT
  .app-layout {
    background-image: url("~assets/icon/pattern-light-mode.svg");
    background-color: $bg-light-color;
  }

  .render-tab-bar {
    background-color: $bg-light-color;
  }

  .ant-layout {
    &-sider {
      background: linear-gradient(
        175deg,
        rgba(255, 255, 255, 0.5) 0%,
        rgba(255, 255, 255, 0.25) 50%,
        rgba(255, 255, 255, 0) 100%
      );

      background-size: 100% 100%;
      backdrop-filter: blur(10px); /* apply a blur to the background */
      transform: translateZ(0); /* trigger hardware acceleration */

      &::after {
        background: linear-gradient(
          4.01deg,
          rgba(217, 217, 217, 0) 0%,
          rgba(217, 217, 217, 0) 39.73%,
          rgba(245, 163, 44, 0) 42.48%,
          rgba(245, 163, 44, 0.5) 100%
        );
      }
    }
  }

  //TYPOGRAPHY
  .ant-typography.card-subtitle {
    color: $grey-light-color;
  }

  .ant-typography.caption {
    color: $emphasis-medium-light-color;
  }

  //BUTTON
  .ant-btn {
    &.sider-trigger {
      color: $emphasis-small-light-color;
    }

    &.ant-btn-dashed {
      background-color: rgba(0, 0, 0, 0.08);
      color: $emphasis-medium-light-color;
    }
  }

  //RADIO
  .ant-radio-group {
    .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) {
      color: $emphasis-medium-light-color;
    }
  }

  //TABS
  .ant-tabs {
    .ant-tabs-tab {
      color: $emphasis-medium-light-color;
      &-active {
        color: $primary-color;
      }
    }
  }

  //CARD
  .ant-card {
    box-shadow:
      0px 2px 2px rgba(0, 0, 0, 0.14),
      0px 3px 1px rgba(0, 0, 0, 0.12),
      0px 1px 5px rgba(0, 0, 0, 0.2);
  }

  //DRAWER
  .ant-drawer {
    .close-icon {
      color: $emphasis-medium-light-color;
    }

    .ant-drawer-header {
      border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    }

    .ant-drawer-content {
      background-color: #fdfdfd;
    }
  }
}
