@import 'scss/_variables';
@import 'scss/_mixins';

.theme {
  :global {
    .all-machines-tabs-wrapper {
      &::before {
        border-bottom: 2px solid rgba(25, 22, 22, 0.7);
      }
    }
  }
}
