@import 'scss/_variables';
@import 'scss/_mixins';

.sider-menu {
  position: relative;
  padding-top: 0;
  padding-left: 16px;
  padding-right: 16px;

  :global {
    .ant-menu-title-content {
      font-weight: 500;
    }

    .ant-menu svg {
      width: 18px;
      height: 18px;
    }

    .ant-menu-item .favourite-icon {
      width: 16px;
      height: 16px;
    }

    .ant-spin {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .menu-scrollbar {
      height: calc(100vh - $header-lg);
      overflow: auto;
      padding-right: 16px;
      border-radius: 4px;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #0c0a0b;
      }
      &::-webkit-scrollbar-thumb {
        background: linear-gradient(14.93deg, #ff2f6180 -42.27%, #ffb801b3 95.89%);
        border-radius: 4px;
      }
    }
  }
}

:global(.menu-drawer .ant-drawer-body) {
  position: relative;
}

:global(.menu-drawer .logo-wrapper) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

:global(.menu-drawer .app-logo) {
  width: auto;
  height: 40px;
  border-radius: 0;
}

:global(.hamburger-menu .app-logo) {
  width: auto;
  height: 40px;
  border-radius: 0;
}

:global(.menu-drawer .ant-menu svg) {
  width: 20px;
  height: 20px;
}

:global(.menu-drawer .ant-menu-item .favourite-icon) {
  width: 18px;
  height: 18px;
}

:global(.menu-drawer .ant-spin) {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
