@import 'scss/_variables';
@import 'scss/_mixins';

.theme {
  :global {
    .hamburger-icon {
      color: $emphasis-medium-dark-color;
    }
  }
}
