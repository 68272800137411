@import 'scss/_variables';
@import 'scss/_mixins';

.machine-details-box {
  padding-top: 16px;
  padding-left: 16px;

  @include breakpoint('size-xl-down') {
    position: absolute;
    padding-top: 24px;
    padding-left: 0;
    padding-right: 0;
  }

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-space {
      margin-bottom: 8px;
    }

    .title-wrapper {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 16px;

      h4.ant-typography {
        font-weight: 600;
        font-size: 18px;
      }

      .buttons-wrapper {
        display: flex;
        gap: 8px;
        margin-left: auto;
      }
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;

      margin-bottom: 16px;

      .title-value-wrapper {
        margin-bottom: 8px;

        .title {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 2.4px;
          text-transform: uppercase;
        }

        .value {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    .anticon-question-circle {
      color: $primary-color;
    }

    .bottom-bar-wrapper {
      .reset-date {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.45px;
        color: $darker-grey-color;
      }
    }

    .buttons-wrapper {
      display: flex;
      gap: 8px;

      &--bottom {
        margin-bottom: 8px;
      }
    }
  }
}

.machine-details-drawer {
  :global {
    .ant-drawer-wrapper-body {
      .ant-drawer-header {
        height: auto !important;
        line-height: auto !important;
      }

      .ant-drawer-close {
        margin-left: auto;
        margin-right: 0;

        svg {
          color: $primary-color;
        }
      }
    }

    .ant-drawer-title {
      display: flex;
      gap: 8px;
    }

    .title-wrapper {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 16px;
    }

    .buttons-wrapper {
      display: flex;
      gap: 8px;
      margin-left: auto;
    }

    .ant-drawer-body {
      height: calc(100vh - $header-lg);
      overflow: auto;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #0c0a0b;
      }
      &::-webkit-scrollbar-thumb {
        background: linear-gradient(14.93deg, #ff2f6180 -42.27%, #ffb801b3 95.89%);
        border-radius: 4px;
      }
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;

      margin-bottom: 16px;

      .title-value-wrapper {
        margin-bottom: 8px;

        .title {
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 2.4px;
          text-transform: uppercase;
        }

        .value {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    .bottom-bar-wrapper {
      .reset-date {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.45px;
        color: $darker-grey-color;
      }
    }

    .buttons-wrapper {
      display: flex;
      gap: 8px;

      &--bottom {
        margin-top: 8px;
      }
    }
  }
}
