// LOCAL FONTS - Poppins Regular
@font-face {
  font-family: 'Poppins';
  src: url('~assets/fonts/Poppins-Regular.eot') format('opentype'),
    url('~assets/fonts/Poppins-Regular.ttf') format('truetype'),
    url('~assets/fonts/Poppins-Regular.woff') format('woff'),
    url('~assets/fonts/Poppins-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

// LOCAL FONTS - Poppins Medium
@font-face {
  font-family: 'Poppins';
  src: url('~assets/fonts/Poppins-Medium.eot') format('opentype'),
    url('~assets/fonts/Poppins-Medium.ttf') format('truetype'),
    url('~assets/fonts/Poppins-Medium.woff') format('woff'),
    url('~assets/fonts/Poppins-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

// LOCAL FONTS - Poppins Semibold
@font-face {
  font-family: 'Poppins';
  src: url('~assets/fonts/Poppins-SemiBold.eot') format('opentype'),
    url('~assets/fonts/Poppins-SemiBold.ttf') format('truetype'),
    url('~assets/fonts/Poppins-SemiBold.woff') format('woff'),
    url('~assets/fonts/Poppins-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

// LOCAL FONTS - Poppins Bold
@font-face {
  font-family: 'Poppins';
  src: url('~assets/fonts/Poppins-Bold.eot') format('opentype'),
    url('~assets/fonts/Poppins-Bold.ttf') format('truetype'),
    url('~assets/fonts/Poppins-Bold.woff') format('woff'),
    url('~assets/fonts/Poppins-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  .rounded-style {
    border-radius: 40px;
  }

  .app-layout {
    height: 100%;
  }

  .react-icon {
    font-size: 21px;
    vertical-align: middle;
  }
}
