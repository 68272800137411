@import 'scss/_variables';
@import 'scss/_mixins';

.theme {
  :global {


    .value {
      color: $green-light-color;
    }
  }
}
