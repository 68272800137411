@import 'scss/_variables';
@import 'scss/_mixins';

.barchart-card {
  width: 100%;
  margin-bottom: 24px;

  :global {
    .ant-card-body {
      position: relative;

      display: block;
      padding-left: 48px;
      padding-right: 48px;
    }

    .nav-prev {
      svg {
        transform: scaleX(-1);
      }
    }

    .nav-prev,
    .nav-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .nav-prev {
      left: 4px;
    }

    .nav-next {
      right: 4px;
    }
  }
}
