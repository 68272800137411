@import 'scss/_variables';
@import 'scss/_mixins';

.chart-box {
  width: 100%;

  :global {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    h4.ant-typography {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 8px;

      .unit {
        margin-left: 4px;
      }
    }

    .values-wrapper {
      .ant-col {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    .legend {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 24px;
      padding-bottom: 24px;

      @include breakpoint('size-sm-down') {
        flex-wrap: wrap;
        gap: 8px;
        justify-content: flex-start;
      }
    }

    .legend-item {
      display: flex;
      align-items: center;

      cursor: pointer;

      &--hidden {
        opacity: 0.4;
      }
    }

    .legend-color {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 8.4px;
      margin-right: 8px;

      @include breakpoint('size-sm-down') {
        width: 16px;
        height: 16px;
        border-radius: 5px;
      }
    }

    .legend-label {
      font-size: 14px;
      font-weight: 500;

      @include breakpoint('size-sm-down') {
        font-size: 12px;
      }
    }
  }
}

:global(.chart-box--empty .values-wrapper) {
  margin-bottom: 24px;
}
