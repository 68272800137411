// VARIABLES
//////////////////////////////////////////////////////////////////////////
$primary-font-family: 'Poppins', sans-serif;

// COLORS
$primary-color: #f5a32c;
$secondary-color: #040d26;

$black-color: #000000;
$white-color: #ffffff;

$text-light-color: #231f20;
$text-dark-color: #fbfaf8;

$grey-light-color: #6b87af;
$grey-dark-color: #abbcc3;
$darker-grey-color: #8b999e;

$emphasis-high-light-color: rgba($black-color, 0.88);
$emphasis-medium-light-color: rgba($black-color, 0.64);
$emphasis-small-light-color: rgba($black-color, 0.4);

$emphasis-high-dark-color: rgba($white-color, 0.88);
$emphasis-medium-dark-color: rgba($white-color, 0.64);
$emphasis-small-dark-color: rgba($white-color, 0.4);

$theme-switch-day: #ffbf71;
$theme-switch-night: #423966;

$bg-light-color: #fbfaf8;
$bg-dark-color: #231f20;

$border-light-color: rgba($black-color, 0.12);
$border-dark-color: rgba($white-color, 0.12);

$red-light-color: #b01f24;
$red-dark-color: #d1252b;

$green-light-color: #9ac802;
$green-dark-color: #b8ee02;

$blue-color: #1677ff;
$orange-color: #faad14;

// BREAKPOINTS
$size-xs: 480px;
$size-sm: 576px;
$size-md: 768px;
$size-lg: 992px;
$size-xl: 1200px;
$size-xxl: 1600px;

// HEADER HEIGHT
$header-lg: 96px;
$header-sm: 80px;
