@import 'scss/_variables';
@import 'scss/_mixins';

.machine-card {
  width: 100%;

  transition: transform 0.3s ease-in-out;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    transform: scale(1.02) !important;
  }

  :global {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      min-height: 187px;
      transition: background-color 0.3s ease-in-out;
    }

    .top-wrapper {
      display: flex;
      flex-direction: column;

      flex: 1 1 65%;
    }

    .title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      &__left {
        display: flex;
        align-items: center;
        width: 85%;
        svg {
          margin-right: 10px;
        }
      }
    }

    .name-wrapper {
      margin-bottom: 8px;

      .name {
        font-weight: 500;
        margin-right: 4px;
      }
      .serial {
        margin-left: 4px;
      }
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      flex: 1 1 45%;
    }

    .bottom-bar-wrapper {
      padding-top: 8px;
      display: flex;

      .card-date {
        flex-grow: 1;
      }

      svg {
        transition: color 0.3s ease-in-out;
      }
    }

    .card-value {
      font-size: 24px;
      font-weight: 600;
    }
  }
}

.link-map-view {
  top: 16px;
  right: 16px;
  z-index: 10;
  position: absolute;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1) !important;
  }
}
