@import 'scss/_variables';
@import 'scss/_mixins';

.header {
  height: $header-lg !important;
  line-height: 1.2 !important;

  display: flex;
  align-items: center;

  padding-inline: 0 !important;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 40px !important;
  padding-right: 40px !important;

  :global {
    .top-bar-wrapper {
      display: flex;
      align-items: center;
      gap: 32px;
      margin-left: auto;

      .language-btn {
        font-size: 14px !important;
        padding: 0;
      }
    }
    .ant-btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .profile-item {
      width: 40px;
      height: 40px;
    }

    .react-icon {
      cursor: pointer;
    }
  }
}

:global(.ant-layout-header) {
  @include breakpoint('size-sm-down') {
    height: $header-sm !important;

    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 16px !important;
    padding-right: 24px !important;
  }
}

:global(.ant-layout-header .top-bar-wrapper) {
  @include breakpoint('size-sm-down') {
    gap: 12px;
  }
}
