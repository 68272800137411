@import 'scss/_variables';
@import 'scss/_mixins';

.theme {
  background: $theme-switch-day;

  :global {
    .sun {
      position: absolute;
      display: block;
      border-radius: 50%;
      transition: all 0.3s ease-in-out;

      top: 4.5em;
      left: 18em;
      transform: rotate(0deg);
      width: 7em;
      height: 7em;
      background: #fff;
      box-shadow: 3em 3em 0 5em #fff inset, 0 -5em 0 -2.7em #fff, 3.5em -3.5em 0 -3em #fff,
        5em 0 0 -2.7em #fff, 3.5em 3.5em 0 -3em #fff, 0 5em 0 -2.7em #fff, -3.5em 3.5em 0 -3em #fff,
        -5em 0 0 -2.7em #fff, -3.5em -3.5em 0 -3em #fff;
    }
  }
}
