@import 'scss/_variables';
@import 'scss/_mixins';

.sider {
  position: relative;

  :global {
    .ant-space {
      gap: 0 !important;
    }

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .logo-wrapper {
      display: flex;

      align-items: center;

      height: $header-lg;

      padding-top: 24px;
      padding-bottom: 32px;
      padding-left: 40px;
      padding-right: 16px;

      position: relative;
    }

    .sider-trigger {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      flex-shrink: 0;

      transition: all 0.3s ease-in-out;
    }

    .app-logo {
      width: 125px;
      height: auto;

      border-radius: 0;
      transition: width 0.3s ease-in;
    }

    .mini-logo {
      width: 0;
    }

    .ant-space {
      width: 100%;
    }

    .ant-space.news-wrapper {
      overflow-x: hidden;
    }
  }
}

:global(.ant-layout-sider-collapsed) {
  min-width: 98px;

  .sider-trigger {
    position: absolute;
    right: -40px;
  }
}

:global(.ant-layout-sider-collapsed .logo-wrapper) {
  justify-content: center;
  padding-left: 16px;
}

:global(.ant-layout-sider-collapsed .mini-logo) {
  display: block;
  width: 40px;
  height: 40px;
}

:global(.ant-layout-sider-collapsed .full-logo) {
  width: 0;
}

:global(.ant-layout-sider-collapsed .sider-trigger) {
  position: absolute;
  right: -40px;
}

:global(.ant-layout-sider-collapsed .news-card) {
  display: none;
  opacity: 0;
}
