@import 'scss/_variables';
@import 'scss/_mixins';

.out-box {
  width: 295px;
  min-height: 188px;
  flex-shrink: 0;
  margin-right: 24px;

  :global {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    svg {
      width: 24px;
      height: 24px;
      margin: auto;
    }

    .icon-wrapper {
      display: flex;
      height: 35px;
    }

    .card-subtitle {
      margin-bottom: 8px;
    }

    .value-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 8px;

      .label {
        font-size: 14px;
        line-height: 1;
        margin-bottom: 4px;
        font-weight: 500;
      }

      .value {
        font-size: 16px;
        line-height: 1;
        font-weight: 700;

        display: flex;
        align-items: center;
      }

      .unit {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
