@import 'scss/_variables';
@import 'scss/_mixins';

.news-card {
  opacity: 1;

  padding: 32px;
  position: relative;

  display: flex;
  flex-direction: column;

  transition: opacity 0.3s ease-in-out;

  &::before {
    content: '';

    position: absolute;
    top: 60px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -1;

    border-radius: 14px;
  }

  :global {
    .news-img-wrapper {
      align-self: center;

      display: flex;

      width: 180px;
      height: 180px;

      border-radius: 50%;
      margin-bottom: 32px;

      img {
        width: 100%;
        height: auto;

        margin: auto;
      }
    }

    h5.ant-typography {
      font-size: 14px;
      line-height: 20px;

      margin-bottom: 16px;
    }

    span.ant-typography {
      display: inline-block;

      font-size: 12px;
      margin-bottom: 24px;
    }

    .ant-btn.go-to-news-btn {
      align-self: flex-start;
      background-color: #fdca40;
      box-shadow: none;

      &:not(:disabled):hover {
        background-color: #e8c861;
      }

      &:not(:disabled):active {
        background-color: #ab8a31;
      }
    }
  }
}

:global {
  .news-card.news-card--horizontal {
    flex-direction: row;
    align-items: center;

    &::before {
      z-index: 0;
    }

    .news-img-wrapper {
      z-index: 1;
    }

    .news-content {
      padding-left: 30px;
    }
  }
}
