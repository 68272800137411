@import 'scss/_variables';
@import 'scss/_mixins';

.theme {
  :global {
    .name-wrapper {
      .name,
      .serial {
        color: $emphasis-medium-light-color;
      }
    }

    .card-value {
      color: $green-light-color;
    }

    .bottom-bar-wrapper {
      svg {
        color: $text-light-color;
      }
    }
  }
}

:global(.light .machine-card:hover .ant-card-body) {
  background-color: rgba(#d9d9d9, 0.2);
}
