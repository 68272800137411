// TYPOGRAPHY
//////////////////////////////////////////////////////////////////////////
h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography,
div.ant-typography,
span.ant-typography {
  font-family: $primary-font-family;
  margin-top: 0;
  margin-bottom: 0;
}

div.ant-typography {
  font-size: 16px;
}

.ant-typography.caption {
  margin-top: 0 !important;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 2.4px;

  @include breakpoint('size-xl-down') {
    font-size: 14px;
  }
}

.ant-typography.card-subtitle {
  font-size: 14px;
  font-weight: 600;
}

.ant-typography.card-date {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;

  color: $darker-grey-color;
}

.box-value {
  display: flex;
  align-items: center;

  .unit {
    font-weight: 600;
    font-size: 16px;
    margin-right: 8px;
  }

  .numeric {
    font-weight: 700;
    font-size: 24px;
    padding-right: 5px;

    @include breakpoint('size-sm-down') {
      font-size: 20px;
    }
  }
}

@include breakpoint('size-xl-down') {
  h1.ant-typography {
    font-size: 24px;
  }
}

@include breakpoint('size-sm-down') {
  h1.ant-typography {
    font-size: 20px;
  }
}
