@import 'scss/_variables';
@import 'scss/_mixins';

.machine-detail-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  :global {
    .app-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .realtime-tabs-wrapper {
      position: relative;

      padding-right: 40px;
      padding-left: 40px;

      @include breakpoint('size-sm-down') {
        padding-left: 24px;
        padding-right: 0;
      }

      &::before {
        content: '';

        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
      }
    }

    .realtime-tabs.ant-tabs {
      margin-top: 24px;

      @include breakpoint('size-sm-down') {
        margin-top: 16px;
      }
    }

    .realtime-tabs .ant-tabs-nav {
      margin-bottom: 0 !important;

      &::before {
        content: none;
      }
    }

    .ant-spin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .body-wrapper {
      padding-left: 40px;
      padding-right: 40px;

      flex-grow: 1;
      flex-wrap: nowrap;

      @include breakpoint('size-sm-down') {
        padding-left: 24px;
      }
    }

    .tabs-content,
    .timeline-boxes-col {
      padding-top: 24px;
      padding-bottom: 24px;
      padding-right: 16px;

      @include breakpoint('size-xl-down') {
        padding-right: 0;
      }

      @include breakpoint('size-sm-down') {
        padding-top: 16px;
      }
    }

    .details-box-col {
      position: relative;

      flex: 0 1 25%;
      // max-width: 320px;
      min-width: 200px;

      .machine-details-btn-wrapper {
        display: none;
      }

      @include breakpoint('size-xl-down') {
        flex: 0 0 auto;
        min-width: auto;
        border-left: transparent;

        .details-wrapper {
          display: none;
        }

        .machine-details-btn-wrapper {
          display: block;
        }
      }
    }

    .realtime-boxes-col,
    .timeline-boxes-col {
      flex: 1 1 75%;
      max-width: 75%;

      @include breakpoint('size-xl-down') {
        max-width: 100%;
      }
    }

    .realtime-boxes {
      margin-bottom: 16px;

      &--current,
      &--power-factor,
      &--vthd,
      &--ithd {
        padding-bottom: 16px;
      }

      &--dashboard {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
        overflow: visible;
        margin-bottom: 0;

        .ant-card {
          margin-right: 0 !important;
        }
      }
    }

    .slick-prev {
      inset-inline-start: -21px;

      svg {
        transform: scaleX(-1);
      }
    }

    .slick-next {
      inset-inline-end: 0;

      @include breakpoint('size-sm-down') {
        inset-inline-end: 4px;
      }
    }

    .slick-prev,
    .slick-next {
      z-index: 1000;
      width: 40px;
      height: 40px;

      &:not(.slick-disabled) {
        color: $primary-color;
      }

      &:not(.slick-disabled):hover {
        color: $primary-color;
        background-color: rgba(245, 163, 44, 0.06);
      }
    }

    .slick-list {
      cursor: grab;
      overflow: hidden;
      padding: 15px;
      padding-right: 0;
      margin-left: -15px;
      margin-top: -15px;

      @include breakpoint('size-xl-down') {
        .slick-slide {
          display: inline-block;
          float: none;
        }
      }
    }

    /* hide the default scrollbar */
    .slick-list::-webkit-scrollbar {
      display: none;
    }

    @include breakpoint('size-xl-down') {
      .ant-tabs-nav-wrap {
        .ant-tabs-tab {
          padding: 12px 0;
          font-size: 14px;
        }
      }
    }
  }
}
