@import 'scss/_variables';
@import 'scss/_mixins';

.in-out-box {
  width: 530px;
  min-height: 188px;
  flex-shrink: 0;
  margin-right: 24px;

  @include breakpoint('size-xl-down') {
    width: 295px;
    height: auto;
  }

  :global {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .card-subtitle {
      margin-bottom: 8px;
    }

    svg {
      width: 24px;
      height: 24px;

      margin: auto;
    }

    .icon-wrapper {
      display: flex;
      height: 35px;
    }

    .main-row {
      flex-wrap: nowrap;

      @include breakpoint('size-xl-down') {
        flex-direction: column;
      }
    }

    .left-col {
      position: relative;
      width: 50%;
      padding-right: 16px;

      @include breakpoint('size-xl-down') {
        width: 100%;
        padding-right: 0;
        padding-bottom: 8px;
      }

      &::after {
        content: '';
        position: absolute;

        width: 1px;
        height: 60%;

        top: 50%;
        transform: translateY(-50%);
        right: 0;

        @include breakpoint('size-xl-down') {
          width: 60%;
          height: 1px;

          top: auto;
          bottom: 0;
          right: 50%;
          transform: translateX(50%);
        }
      }
    }

    .right-col {
      width: 50%;
      padding-left: 16px;

      @include breakpoint('size-xl-down') {
        width: 100%;
        padding-left: 0;
        padding-top: 8px;
      }
    }

    .ant-col {
      .card-subtitle {
        margin-top: 0;
      }
    }

    .value-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 8px;

      .label {
        font-size: 14px;
        line-height: 1;
        margin-bottom: 4px;
        font-weight: 500;
      }

      .value {
        font-size: 16px;
        line-height: 1;
        font-weight: 700;

        display: flex;
        align-items: center;
      }

      .unit {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
