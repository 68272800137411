@import 'scss/_variables';
@import 'scss/_mixins';

.popup-wrapper {
  :global {
    .mapboxgl-popup-content {
      min-width: 260px;
      padding: 0px;
      border-radius: 14px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12),
        0px 1px 5px rgba(0, 0, 0, 0.2);
    }
    .mapboxgl-popup-close-button {
      display: none;
    }
  }
}

:global {
  .carousel-wrapper {
    padding: 8px;
    border-radius: 14px;

    .ant-carousel {
      .slick-slider {
        padding-bottom: 20px;
      }
    }
  }

  .carousel-dark {
    background-color: #191616;

    .slick-dots {
      li {
        button {
          background-color: #fff;
        }

        &.slick-active {
          button {
            background-color: $primary-color;
          }
        }
      }
    }
  }
  .carousel-light {
    .slick-dots {
      li {
        button {
          background-color: #191616;
        }

        &.slick-active {
          button {
            background-color: $primary-color;
          }
        }
      }
    }
  }
}

.machine-card {
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
  box-shadow: none !important;

  :global {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      min-height: 190px;
      transition: background-color 0.3s ease-in-out;
    }

    .top-wrapper {
      display: flex;
      flex-direction: column;

      flex: 1 1 65%;
    }

    .title-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;

      &__left {
        display: flex;
        align-items: center;
        width: 85%;
        svg {
          margin-right: 10px;
        }
      }
    }

    .name-wrapper {
      margin-bottom: 8px;

      .name {
        font-weight: 500;
        margin-right: 4px;
      }
      .serial {
        margin-left: 4px;
      }
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      flex: 1 1 45%;
    }

    .bottom-bar-wrapper {
      padding-top: 8px;
      display: flex;

      .card-date {
        flex-grow: 1;
      }

      svg {
        transition: color 0.3s ease-in-out;
      }
    }

    .card-value {
      font-size: 24px;
      font-weight: 600;
    }
  }
}

:global(.machine-card:hover .bottom-bar-wrapper svg) {
  color: $primary-color;
}

:global(.machine-card:hover) {
  transform: scale(1);
}
