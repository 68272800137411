@import 'scss/_mixins';
@import 'scss/_variables';

.home-page {
  height: 100%;

  :global {
    .app-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .counter-device {
      color: $green-light-color;
    }

    .ant-btn.add-machine-btn {
      @include breakpoint('size-sm-down') {
        justify-content: center;
        font-size: 0;
        height: 40px;
        width: 40px;

        padding: 0;

        .btn-icon {
          width: 20px;
          height: 20px;

          margin: auto;
        }
      }
    }

    .custom-tabs-wrapper {
      height: 100%;

      .top-title {
        padding-left: 0;
      }

      .render-tab-bar {
        position: relative;

        padding-left: 40px;
        padding-right: 40px;

        @include breakpoint('size-sm-down') {
          padding-left: 24px;
          padding-right: 24px;
        }

        &::before {
          content: '';

          position: absolute;
          right: 0;
          left: 0;
          bottom: 0;
        }

        .ant-tabs-nav {
          gap: 6px;
          margin-bottom: 0 !important;

          .ant-tabs-tab+.ant-tabs-tab {
            @include breakpoint('size-sm-down') {
              margin-left: 16px;
            }
          }

          .ant-tabs-tab:nth-last-of-type(2) {
            margin-right: 16px;
          }

          &::before {
            content: none;
          }


        }
      }
    }

    .ant-tabs-nav .ant-tabs-nav-wrap {
      &::before {
        box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.2) !important;
      }

      &::after {
        box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.2) !important;
      }
    }

    .ant-tabs {
      height: 100%;
    }

    .ant-tabs-tab {
      font-size: 18px;

      @include breakpoint('size-xl-down') {
        padding: 12px 0;
        font-size: 14px;
      }
    }

    .ant-tabs-content-holder {
      position: relative;
      padding-top: 24px;
      padding-left: 40px;
      padding-right: 40px;

      @include breakpoint('size-sm-down') {
        padding-top: 16px;
        padding-left: 24px;
        padding-right: 24px;
      }
    }

    .ant-tabs-content {
      position: static;
    }

    .ant-spin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .render-tab-bar {
      z-index: 999;
    }

    .list-view {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 32px;

      @include breakpoint('size-sm-down') {
        margin-bottom: 16px;
      }
    }

    .machine-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, 306px);
      gap: 24px;

      @include breakpoint('size-xl-down') {
        grid-template-columns: repeat(2, 306px);
      }

      @include breakpoint('size-md-down') {
        grid-template-columns: repeat(1, minmax(306px, auto));
      }
    }
  }
}
