@import 'scss/_variables';
@import 'scss/_mixins';

.chart-box {
  :global {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    h4.ant-typography {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 8px;
    }

    .values-wrapper {
      .ant-col {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    canvas {
      max-width: 100% !important;
    }

    .legend {
      width: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;
      justify-content: flex-start;
      padding-top: 24px;
      padding-bottom: 24px;
    }

    .legend-item {
      display: flex;
      align-items: center;

      cursor: pointer;

      &--hidden {
        opacity: 0.4;
      }
    }

    .legend-color {
      display: inline-block;

      width: 16px;
      height: 16px;
      border-radius: 5px;
      margin-right: 8px;
    }

    .legend-label {
      font-size: 12px;
      font-weight: 500;
    }
  }
}
