@import 'scss/_variables';
@import 'scss/_mixins';

.theme {
  &::before {
    background-color: rgba(#d9d9d9, 0.2);
  }

  :global {
    h5.ant-typography {
      color: $black-color;
    }

    span.ant-typography {
      color: rgba($black-color, 0.72);
    }
  }
}
