@import "scss/_variables";
@import "scss/_mixins";

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  cursor: progress;
  align-items: center;
  justify-content: center;
  background-color: rgba($black-color, 0.3);
}
