@import "scss/_variables";
@import "scss/_mixins";

.home-page {
  height: 100%;

  :global {
    .app-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .counter-device {
      color: $green-light-color;
    }

    .ant-btn.add-machine-btn {
      @include breakpoint("size-sm-down") {
        justify-content: center;
        font-size: 0;
        height: 40px;
        width: 40px;

        padding: 0;

        .btn-icon {
          width: 20px;
          height: 20px;

          margin: auto;
        }
      }
    }

    .all-machines-tabs-wrapper {
      position: relative;
      margin-bottom: 1px;

      padding-left: 40px;
      padding-right: 40px;

      @include breakpoint("size-sm-down") {
        padding-left: 24px;
        padding-right: 16px;
      }

      &::before {
        content: "";

        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
      }

      .ant-tabs-nav {
        gap: 6px;
        margin-bottom: 0 !important;

        &::before {
          content: none;
        }

        .ant-tabs-tab+.ant-tabs-tab {
          @include breakpoint('size-sm-down') {
            margin-left: 16px;
          }
        }

        .ant-tabs-tab:nth-last-of-type(2) {
          margin-right: 24px;
        }
      }
    }

    .ant-tabs-nav .ant-tabs-nav-wrap {
      &::before {
        box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.2) !important;
      }

      &::after {
        box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.2) !important;
      }
    }

    .ant-tabs {
      flex-grow: 1;
    }

    .ant-tabs-tab {
      font-size: 18px;

      @include breakpoint("size-xl-down") {
        padding: 12px 0;
        font-size: 14px !important;
      }
    }

    .ant-tabs-content {
      position: static;
    }

    .ant-spin {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .list-view {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      margin-bottom: 32px;

      padding-top: 24px;
      padding-left: 40px;
      padding-right: 40px;

      @include breakpoint("size-sm-down") {
        padding-top: 16px;
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: 16px;
      }
    }

    .machine-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, 306px);
      gap: 40px;

      @include breakpoint("size-xl-down") {
        gap: 32px;
      }

      @include breakpoint("size-sm-down") {
        gap: 16px;
      }
    }
  }
}
