@import 'scss/_variables';
@import 'scss/_mixins';

// VARIABLES
$table-border-color: rgba(0, 0, 0, 0.08);

// STYLES
.timeline-data {
  margin-top: 68px;

  @include breakpoint('size-sm-down') {
    margin-top: 60px;
  }

  :global {
    .month-switch {
      span {
        @include breakpoint('size-sm-down') {
          font-size: 14px;
        }
      }

      .ant-radio-button-wrapper {
        margin-right: 8px;
        border-radius: 14px;

        &::before {
          content: none;
        }
      }
    }

    .chart-timerange-wrapper {
      gap: 8px;

      .chart-timerange {
        display: flex;
        justify-content: space-between;

        .download-report {
          .ant-btn-icon {
            display: none;
            margin: 0;
          }
        }

        @include breakpoint('size-sm-down') {
          .download-report {
            button {
              width: 40px;
              padding-inline-start: 0;
              padding-inline-end: 0;
            }

            span {
              display: none;
            }

            .ant-btn-icon {
              display: block;
            }
          }
        }
      }
    }

    .ant-table-thead {
      .ant-table-row-expand-icon-cell {
        border-left: 1px solid $table-border-color !important;
        border-top: 1px solid $table-border-color !important;
        border-bottom-color: $table-border-color !important;
      }

      tr {
        th {
          border: 1px solid $table-border-color !important;
          &[scope='colgroup'] {
            border-left: none !important;
          }
          &::before {
            display: none;
          }
        }
        &:last-child {
          th {
            border-top: none !important;
            &[scope='col'] {
              border-left: none !important;
            }
          }
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          padding: 16px 12px;
          &:first-child {
            border-left: 1px solid $table-border-color;
          }
          &:nth-child(even) {
            border-right: 1px solid $table-border-color;
          }
          &:nth-child(5) {
            border-right: 1px solid $table-border-color;
          }
        }
      }
    }

    .saving-column-data {
      display: flex;
      justify-content: space-between;
    }

    .table-metric-unit {
      font-size: 11px;
    }

    .timeline-boxes-col {
      > .ant-row {
        margin-bottom: 24px;
      }
    }

    .timeline-chartbox-wrapper {
      .summary-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .values-wrapper {
          align-self: center;
        }

        .ant-btn {
          flex-shrink: 0;
        }
      }

      .box-value {
        .numeric {
          font-size: 20px;
        }

        .value {
          font-size: 14px;
        }
      }
    }

    .chart-wrapper {
      flex-grow: 1;
    }

    .barchart-card {
      margin-bottom: 40px;
    }

    .barchart-card .ant-card-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .timeline-mobile-table-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;

      @include breakpoint('size-sm-down') {
        .responsive-title {
          font-size: 10px;
        }
      }

      .ant-radio-group {
        align-self: flex-end;
      }
    }

    .ant-table-wrapper {
      max-width: 100%;
      width: 100%;

      @include breakpoint('size-sm-down') {
        .ant-table-cell {
          padding: 8px;
          font-size: 12px;
        }
      }

      table {
        @include breakpoint('size-sm-down') {
          width: 100%;
          table-layout: fixed !important;
        }
      }

      .ant-table-content {
        overflow-x: auto;
      }
    }

    .timeline-tabs-wrapper {
      position: relative;
      margin-bottom: 16px;

      @include breakpoint('size-xl-down') {
        padding-left: 40px;
      }

      @include breakpoint('size-sm-down') {
        padding-left: 24px;
      }

      &::before {
        content: '';

        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
      }
    }

    .timeline-tabs .ant-tabs-nav {
      margin-bottom: 0 !important;

      &::before {
        content: none;
      }
    }
  }
}
