@import 'scss/_variables';
@import 'scss/_mixins';

.theme {
  :global {
    .box-value .ant-typography {
      color: #312d2e;
    }
  }
}

:global(.light .evaluation-card:hover .ant-card-body) {
  background-color: rgba(#d9d9d9, 0.2);
}
