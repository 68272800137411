@import 'scss/_variables';
@import 'scss/_mixins';

.theme {
  :global {
    .ant-drawer-title,
    .ant-drawer-title .ant-typography {
      color: $red-light-color;
    }

    .title-wrapper {
      h4.ant-typography {
        color: #5c5557;
      }
    }

    .title-value-wrapper {
      .title {
        color: $emphasis-medium-light-color;
      }

      .value {
        color: $text-light-color;
      }
    }
  }
}
