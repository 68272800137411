@import 'scss/_variables';
@import 'scss/_mixins';

.evaluation-card {
  width: 350px;
  min-height: 188px;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;

  :global {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      height: 100%;
      transition: background-color 0.3s ease-in-out;
    }

    .top-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      flex: 1 1 65%;
    }

    .title-wrapper {
      display: flex;
      align-items: flex-start;

      svg {
        margin-right: 10px;
      }
    }

    .name-wrapper {
      display: flex;
      flex-direction: column;

      span {
        font-weight: 500;
      }
    }

    .range-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1 45%;
    }

    .bottom-bar-wrapper {
      .value-wrapper {
        display: flex;
        //justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        .unit {
          font-size: 14px;
          line-height: 1;
          //margin-bottom: 4px;
          font-weight: 600;
          padding-right: 8px;
        }

        .value {
          line-height: 1;
          font-size: 24px;
          font-weight: 700;
          padding-right: 5px;
        }
      }

      svg {
        transition: color 0.3s ease-in-out;
      }
    }

    .card-value {
      font-size: 24px;
      font-weight: 600;
    }
  }
}

:global(.evaluation-card:hover) {
  transform: scale(1.02);
}
