@import 'scss/_variables';
@import 'scss/_mixins';

.theme {
  background: $theme-switch-night;

  :global {
    .moon {
      position: absolute;
      display: block;
      border-radius: 50%;
      transition: all 0.3s ease-in-out;

      top: 3em;
      left: 3em;
      transform: rotate(-75deg);
      width: 10em;
      height: 10em;
      background: $theme-switch-night;
      box-shadow: 3em 2.5em 0 0em #fff inset, #fff 0em -7em 0 -4.5em, #fff 3em 7em 0 -4.5em,
        #fff 2em 13em 0 -4em, #fff 6em 2em 0 -4.1em, #fff 8em 8em 0 -4.5em, #fff 6em 13em 0 -4.5em,
        #fff -4em 7em 0 -4.5em, #fff -1em 10em 0 -4.5em;
    }
  }
}
