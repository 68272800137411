@import 'scss/_variables';
@import 'scss/_mixins';

.ecostab-box {
  width: 265px;
  min-height: 188px;
  flex-shrink: 0;
  margin-right: 24px;

  :global {
    .ant-card-body {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    svg {
      width: 24px;
      height: 24px;

      margin: auto;
    }

    .card-subtitle {
      margin-bottom: 8px;
    }

    .content-wrapper {
      .icon-wrapper {
        display: flex;
        height: 35px;
      }
    }

    .bottom-bar-wrapper {
      display: flex;
      margin-top: auto;

      .card-date {
        flex-grow: 1;
      }

      svg {
        transition: color 0.3s ease-in-out;
      }
    }
  }
}
