@import 'scss/_variables';
@import 'scss/_mixins';

.page-header {
  display: flex;
  flex-direction: column;
  @include breakpoint('size-xl-down') {
    padding-top: 16px;
  }

  :global {
    .back-btn-link {
      width: fit-content;

      .back-btn {
        margin-bottom: 5px;
        margin-left: 16px;

        svg {
          margin-right: 8px;
          transform: rotate(180deg);
        }

        @include breakpoint('size-xl-down') {
          margin-top: -16px;
          margin-bottom: 16px;
        }

        @include breakpoint('size-sm-down') {
          justify-content: center;
          font-size: 0;
          height: 40px;
          width: 40px;

          padding: 0;

          svg {
            width: 20px;
            height: 20px;

            margin: auto;
          }
        }
      }
    }

    .top {
      display: flex;
      justify-content: space-between;

      @include breakpoint('size-sm-down') {
        align-items: center;
        // flex-direction: column;
        // gap: 8px;
      }
    }

    .top-title {
      width: auto;
      padding-left: 40px;

      @include breakpoint('size-sm-down') {
        padding-left: 24px;
      }
    }

    .title-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 5px;
      flex: 1;
      flex-wrap: wrap !important;

      svg {
        width: 32px;
        height: 32px;

        @include breakpoint('size-xl-down') {
          width: 24px;
          height: 24px;
        }
      }
    }

    .title-icon-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 8px;

      svg {
        margin-top: 4px;
        flex-shrink: 0;
      }
    }

    .caption {
      display: flex;
      flex-wrap: wrap !important;

      @include breakpoint('size-xl-down') {
        flex-wrap: wrap !important;
      }
    }

    .extra {
      display: flex;
      width: auto;

      padding-left: 40px;
      padding-right: 40px;

      @include breakpoint('size-sm-down') {
        padding-right: 24px;
        margin-left: auto;

        .add-machine-btn {
          transform: translateY(-50%);
        }
        .ant-btn-icon {
          margin-inline-end: 0 !important;
        }
      }
    }
  }
}

:global(.page-header.evaluation .caption) {
  margin-left: 0 !important;
}
