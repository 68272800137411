@import 'scss/_variables';
@import 'scss/_mixins';

.theme {
  :global {
    .name-wrapper {
      .name,
      .serial {
        color: $emphasis-medium-dark-color;
      }
    }

    .card-value {
      color: $green-dark-color;
    }

    .bottom-bar-wrapper {
      svg {
        color: $text-dark-color;
      }
    }
  }
}

:global(.dark .machine-card:hover .ant-card-body) {
  background-color: rgba(#512325, 0.1);
}
