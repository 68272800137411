@import 'scss/_variables';
@import 'scss/_mixins';

.theme {
  :global {
    .details-box-col {
      border-left: 2px solid rgba(#191616, 0.7);
    }

    .ant-typography.card-subtitle.card-subtitle--top {
      color: #d9dcd6;
    }

    .timeline-data {
      border-top: 2px solid rgba(#191616, 0.7);
    }

    .realtime-tabs-wrapper {
      &::before {
        border-bottom: 2px solid rgba(25, 22, 22, 0.7);
      }
    }

    .timeline-tabs-wrapper {
      &::before {
        border-bottom: 2px solid rgba(255, 255, 255, 0.08);
      }
    }

    .machine-details-box {
      .ant-space,
      .ant-space .ant-typography {
        color: $red-dark-color;
      }

      .title-wrapper {
        h4.ant-typography {
          color: #d9dcd6;
        }
      }

      .title-value-wrapper {
        .title {
          color: $emphasis-medium-dark-color;
        }

        .value {
          color: $text-dark-color;
        }
      }
    }

    .chart-box {
      h4.ant-typography {
        color: #d9dcd6;
      }

      .legend-item {
        &--hidden {
          .legend-color {
            background-color: $emphasis-small-dark-color !important;
          }

          .legend-label {
            color: $emphasis-small-dark-color;
          }
        }
      }
    }

    .box-export-icon {
      color: $green-dark-color;
      transform: rotate(180deg);
    }

    .box-import-icon {
      color: $red-dark-color;
    }

    .box-value.ant-typography {
      color: $text-dark-color;
    }

    .in-out-box {
      .main-row {
        > .ant-col:first-child {
          &::after {
            background-color: rgba(255, 255, 255, 0.08);
          }
        }
      }
    }

    .in-out-box,
    .out-box {
      .value-wrapper {
        .unit {
          color: $emphasis-high-dark-color;
        }

        .value {
          color: $text-dark-color;
        }
      }
    }
  }
}
