@import 'scss/_variables';
@import 'scss/_mixins';

.theme {
  &::before {
    background-color: rgba(#512325, 0.1);
  }

  :global {
    h5.ant-typography {
      color: $white-color;
    }

    span.ant-typography {
      color: rgba($white-color, 0.72);
    }
  }
}
