@import 'scss/_variables';
@import 'scss/_mixins';

.error-page {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;

  :global {
    .content-col {
      position: relative;
      padding: 24px 40px;

      flex: 1 1 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &::after {
        content: '';

        position: absolute;
        top: 0;
        right: 0;

        display: block;
        height: 100%;
        width: 3px;
      }
    }

    .top-bar-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title-wrapper {
      text-align: center;
      margin-bottom: 64px;

      @include breakpoint('size-sm-down') {
        margin-bottom: 40px;
      }

      h1 {
        margin-bottom: 8px;
      }
    }

    .subtitle {
      span {
        font-size: 18px;

        @include breakpoint('size-sm-down') {
          font-size: 16px;
        }
      }
    }

    .app-logo {
      width: 125px;
      height: auto;

      border-radius: 0;

      @include breakpoint('size-sm-down') {
        width: auto;
        height: 40px;
        border-radius: 0;
      }
    }

    .content {
      max-width: 336px;
      margin: 0 auto;
    }

    .media-col {
      flex: 1 1 60%;
      max-width: 60%;

      display: flex;

      @include breakpoint('size-xl-down') {
        display: none;
      }
    }

    .image-wrapper {
      width: 40%;

      margin: auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
