@import 'scss/_variables';
@import 'scss/_mixins';

.theme {
  background-image: url('~assets/icon/pattern-light-mode.svg');
  background-color: $bg-light-color;

  :global {
    .subtitle {
      span {
        color: $emphasis-medium-light-color;
      }

      strong {
        color: $emphasis-high-light-color;
      }
    }
  }
}
