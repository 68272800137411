@import 'scss/_variables';
@import 'scss/_mixins';

.evaluation-data {
  margin-top: 68px;

  @include breakpoint('size-sm-down') {
    margin-top: 60px;
  }

  :global {
    .cards-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, 350px);

      gap: 24px;
    }

    .saving-box {
      width: 260px;
      min-height: 188px;
      margin-bottom: 32px;

      .ant-card-body {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .card-subtitle {
        margin-bottom: 8px;
      }

      .content-wrapper {
        .icon-wrapper {
          display: flex;
          height: 35px;
        }
      }
    }

    .timeline-boxes-col {
      > .ant-row {
        margin-bottom: 16px;
      }
    }

    .barchart-card .ant-card-body {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .timeline-chartbox-wrapper {
      display: flex;
      flex-direction: column;

      .summary-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .box-value {
        .numeric {
          font-size: 20px;
        }

        .value {
          font-size: 14px;
        }
      }
    }
    .saving-column-data {
      display: flex;
      justify-content: space-between;
    }

    .nav-prev {
      margin-right: 8px;
      svg {
        transform: scaleX(-1);
      }
    }

    .nav-next {
      margin-left: 8px;
    }

    .chart-wrapper {
      flex-grow: 1;
    }

    .barchart-card {
      margin-bottom: 40px;
    }

    .ant-table-wrapper {
      max-width: 100%;
      width: 100%;

      .ant-table-content {
        overflow-x: auto;
      }
    }
  }
}
