//Make footer always stick to bottom page

// LAYOUT
//////////////////////////////////////////////////////////////////////////
.hamburger-menu {
  display: none;
}

@include breakpoint('size-lg-down') {
  .ant-layout-sider {
    display: none;
  }

  .hamburger-menu {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}
