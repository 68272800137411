@import 'scss/_variables';
@import 'scss/_mixins';

.switcher {
  font-size: 10%;
  position: relative;
  height: 24px;
  width: 42px;
  border-radius: 8.4px;
  transition: all 0.3s ease-in-out;
  background: $theme-switch-night;
  cursor: pointer;
}
