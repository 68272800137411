@import 'scss/_variables';
@import 'scss/_mixins';

.theme {
  background-image: url('~assets/icon/pattern-dark-mode.svg');
  background-color: $bg-dark-color;

  :global {
    .subtitle {
      span {
        color: $emphasis-medium-dark-color;
      }

      strong {
        color: $emphasis-high-dark-color;
      }
    }
  }
}
