@import 'scss/_variables';
@import 'scss/_mixins';

.theme {
  :global {
    .box-value .ant-typography {
      color: $text-dark-color;
    }
  }
}

:global(.dark .evaluation-card:hover .ant-card-body) {
  background-color: rgba(#512325, 0.1);
}
