@import 'scss/_variables';
@import 'scss/_mixins';

.machine-map-filters {
  top: 12px;
  right: 12px;
  z-index: 100;
  position: absolute;
  justify-content: flex-end;
  width: calc(100% - 100px);

  @include breakpoint('size-sm-down') {
    align-items: flex-end;
    flex-direction: column;
  }
  :global { }
}
